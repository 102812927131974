<template>
  <tr  v-show="!item.isEdit" @click="openEditLine(index)">
    <td>{{ index + 1 }}</td>
    <td v-if="false">{{ item.sku }}</td>
    <td>{{ item.product_name }}</td>
    <td>{{ item.stock }}</td>
    <td>{{ item.quantity }}</td>
    <td>{{ item.rate }}</td>
    <td v-if="false">{{ item.amount }}</td>
    <td v-if="false">{{ item.discount_percent }}</td>
    <td v-if="false">{{ item.discount_amount }}</td>
    <td v-if="false">{{ item.offer_discount }}</td>
    <td v-if="false">{{ amountAfterDiscount }}</td>
    <td v-if="false">{{ item.vat }} </td>
    <td v-if="false">{{ item.vat_amount }} </td>
    <td>{{ totalAmount }} </td>
    <td>{{ item.free_quantity }}</td>

    <td>
      <button
          class="btn btn-sm btn-danger px-1"
          @click="deleteItem(index)"
      >
        <i class="fa fa-trash"></i>
      </button>
    </td>
  </tr>
  <tr  v-show="item.isEdit" :class="item.isEdit && 'tr-box-shadow'">
    <td>{{ index + 1 }}</td>
    <td v-if="false">{{ item.sku }}</td>
    <td>{{ item.product_name }}</td>
    <td>{{ item.stock }}</td>
    <td>
      <div class="td-inline">
        <input
            v-model="item.quantity"
            type="number" class="form-control w-100px"
            placeholder="Qty" :min="0"
            @keyup="validateQuantity()"
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            v-model="item.rate"
            type="number" class="form-control w-100px"
            placeholder="Rate" :min="0"
        >
      </div>
    </td>
    <td v-if="false">{{ amount }}</td>
    <td v-if="false">
      <div class="td-inline">
        <input
            v-model="item.discount_percent"
            type="number" class="form-control w-100px"
            placeholder="TD/Commission (%)" :min="0"
        >
      </div>
    </td>
    <td v-if="false">
      <div class="td-inline">
        <input
            v-model="item.discount_amount"
            type="number" class="form-control w-100px"
            placeholder="TD/Commission Amount" :min="0"
        >
      </div>
    </td>
    <td v-if="false">{{ item.offer_discount }}</td>
    <td v-if="false">{{ amountAfterDiscount }}</td>
    <td v-if="false">
      <div class="td-inline">
        <vField
            v-model="item.vat"
            id="vat"
            as="select"
            name="vat"
            class="form-select w-100px"
        >
          <option
              v-for="(vat, i) in vatRate"
              :value="vat.value"
              :key="i"
          >
            {{vat.label}}
          </option>
        </vField>
      </div>
    </td>
    <td v-if="false">
      <div class="td-inline">
        <input
            v-model="item.vat_amount"
            type="number" class="form-control w-100px"
            placeholder="Vat Amount" :min="0"
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            v-model="totalAmount"
            type="number" class="form-control w-100px"
            placeholder="Total Amount" :min="0"
        >
      </div>
    </td>
    <td>{{ item.free_quantity }}</td>
  </tr>

</template>
<script setup>
import {computed, inject, ref, watch} from "vue";
import handlePurchase from "@/services/modules/purchase";
import {useRoute} from "vue-router";

const props = defineProps({
  item: Object,
  index: Number,
  onOpenEditItem: Function,
  onDeleteItem: Function,
  vatRate: Number,
  fromReturn: {
    type: Boolean,
    default: false
  }

})
const emit = defineEmits(['onOpenEditItem', 'onDeleteItem']);
const $route = useRoute();
const showError = inject('showError')
const companyId = $route.params.companyId
const companyQuery = `?company_id=${companyId}`;
const {fetchProductByStockId, fetchFreeQuantity} = handlePurchase();

const openEditLine = (i) => {
  emit('onOpenEditItem', i)
}

const deleteItem = (i) => {
  emit('onDeleteItem', i)
}
const amount = computed(() => props.item.amount = (props.item.rate * props.item.quantity).toFixed(2))

const discountPercent = computed(() => props.item.discount_percent);

const offerDiscount = computed(() => props.item.offer_discount);

const amountAfterDiscount = computed(() => {
  const discountAmount = props.item.discount_amount === '' ? 0 : parseFloat(props.item.discount_amount)

  return (amount.value - (discountAmount + offerDiscount.value)).toFixed(2)
})

const vatAmount = computed(() => {
  if(props.item.vat === 0) {
    return 0
  }

  if(props.item.vat) {
    return (props.item.vat/100 * amountAfterDiscount.value).toFixed(2)
  }
  return (props.item.vat/100 * amountAfterDiscount.value).toFixed(2);
})

const totalAmount = computed(() => {
  if(props.item.vat_amount === 0) {
    return props.item.total_amount = amountAfterDiscount.value
  }
  return props.item.total_amount = (parseFloat(amountAfterDiscount.value) + parseFloat(props.item.vat_amount)).toFixed(2)
})

watch(vatAmount, (newValue, oldValue) => {
  if(! isNaN(oldValue) || !$route.params.invoiceId){
    props.item.vat_amount  = newValue;
  }
})
const productId = computed(() => props.item.product_id)

watch(productId, async () => {
  if (!productId.value) {
    return;
  }

  const productResp = await fetchProductByStockId(productId.value, companyQuery);

  if (!productResp.data) {
    showError("Product not found");
    return;
  }

  const selected = productResp.data;
  props.item.product_id = selected.id;
  props.item.stock_register_id = selected.stock_id;
  props.item.rate = selected.sales_price;
  props.item.amount = props.item.quantity * props.item.rate;
  props.item.name = selected.name;
  props.item.option_name = selected.text;
  props.item.vat = selected.vat_rate;
  props.item.stock_in_hand = selected.stock_in_hand;
  props.item.description = selected.description;
  props.item.batch_number = productResp.batch_number;
  props.item.product = {
    name: selected.name
  };
})

watch(discountPercent, (value) => {
  let discount = 0;
  value = parseFloat(value);
  if(value && value > 0 && amount.value > 0) {
    discount = amount.value * (value/100);
  }
  props.item.discount_percent = value;
  props.item.discount_amount = discount.toFixed(2);
})

const validateQuantity = () => {
  if(props.item.quantity !== null && props.item.quantity < 1){
    showError('Product Quantity must be greater than 0!')
  }
  if (props.item.quantity !== null && props.item.quantity > 0){
    getFreeQuantity();
  }
}

const getFreeQuantity = () => {
  const queryObject = Object.assign({}, {
    company_id: $route.params.companyId,
    quantity: props.item.quantity
  })

  if (props.contactProfileId) {
    queryObject.contact_profile_id = props.contactProfileId
  }

  fetchFreeQuantity(productId.value, `?${new URLSearchParams(queryObject).toString()}`)
      .then(res => {
        if (!res.data) {
          props.item.free_quantity = 0;
          props.item.offer_discount = 0;
        }

        props.item.free_quantity = res.data.free_quantity;
        props.item.offer_discount = res.data.discount_amount;
      }).catch(() => {
        props.item.free_quantity = 0;
        props.item.offer_discount = 0;
  });
}

</script>
<style scoped>
.btn-dlt{
  color: #ea5455;
}
.w-100px{
  width: 100px;
}
</style>